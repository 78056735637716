import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import CarryUs from 'components/CarryUs';

export default function CarryUsPage(props) {
  let {
    data: { contentfulCarryUsPage },
  } = props;

  return (
    <Layout>
      <CarryUs page={contentfulCarryUsPage} />
    </Layout>
  );
}

export const query = graphql`
  query {
    contentfulCarryUsPage {
      title1
      text1 {
        json
      }
      link1
      image1 {
        localFile {
          publicURL
          image: childImageSharp {
            resize(width: 1200, height: 640) {
              src
            }
          }
        }
      }
      title2
      text2 {
        json
      }
      link2
      image2 {
        localFile {
          publicURL
          image: childImageSharp {
            resize(width: 1200, height: 640) {
              src
            }
          }
        }
      }
      title3
      text3 {
        json
      }
      link3
      image3 {
        localFile {
          publicURL
          image: childImageSharp {
            resize(width: 1200, height: 640) {
              src
            }
          }
        }
      }
    }
  }
`;
