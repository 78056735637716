import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import CarryUsForm from './CarryUsForm';

import {
  Spacing,
  Container,
  LinkImage,
} from '@matchbox-mobile/kenra-storybook';
import get from 'lodash.get';

function getItemData(title, text, link, image) {
  return {
    title: title,
    text: text && documentToReactComponents(text.json),
    btnText: 'LEARN MORE',
    btnHref: link,
    img: get(image, 'localFile.image.resize.src'),
  };
}

export default function CarryUs({ page }) {
  let items = [
    getItemData(page.title1, page.text1, page.link1, page.image1),
    getItemData(page.title2, page.text2, page.link2, page.image2),
    getItemData(page.title3, page.text3, page.link3, page.image3),
  ];

  return (
    <>
      {Array.isArray(items) && items.length > 0 && (
        <Spacing removeSpaceTop removeSpaceBottom>
          <Container>
            <LinkImage items={items} />
          </Container>
        </Spacing>
      )}

      <CarryUsForm />
    </>
  );
}
