import React from 'react';

import {
  Spacing,
  SharedStyles,
  Container,
  Fieldset,
  FieldsGroup,
  FieldSelect,
  FieldInput,
  FieldSubmit,
} from '@matchbox-mobile/kenra-storybook';

const { StSectionInfo, StTitle } = SharedStyles;

export default function CarryUsForm() {
  return (
    <>
      <Spacing removeSpaceBottom>
        <StSectionInfo>
          <Container>
            <StTitle colorBlack styleMedium>
              <h2>Join Us</h2>
            </StTitle>
          </Container>
        </StSectionInfo>
      </Spacing>

      <Spacing>
        <Container useMediumWidth>
          <form>
            <Fieldset legend="I Am">
              <FieldSelect
                id="select-id-1"
                label="Please select"
                options={[
                  {
                    label: 'Male',
                    value: 'male',
                  },
                  {
                    label: 'Female',
                    value: 'female',
                  },
                ]}
              />
            </Fieldset>
            <Fieldset legend="Salon name">
              <FieldInput
                type="text"
                id="name-id-1"
                label="Enter salon name if applicable"
              />
            </Fieldset>
            <FieldsGroup>
              <Fieldset legend="Email*">
                <FieldsGroup>
                  <FieldInput
                    type="email"
                    id="email-id-1"
                    label="Your email address is secure and is not shared with any third parties. Privacy Policy"
                  />
                </FieldsGroup>
              </Fieldset>
              <Fieldset legend="Phone*">
                <FieldsGroup widthAuto>
                  <FieldInput
                    type="text"
                    id="num-id-1"
                    label="(###)"
                    size={10}
                  />
                  <FieldInput type="text" id="num-id-2" label="###" size={10} />
                  <FieldInput
                    type="text"
                    id="num-id-3"
                    label="####"
                    size={10}
                  />
                </FieldsGroup>
              </Fieldset>
            </FieldsGroup>
            <Fieldset legend="Name*">
              <FieldsGroup>
                <FieldInput type="text" id="name-id-1" label="First Name" />
                <FieldInput type="text" id="name-id-2" label="Last Name" />
              </FieldsGroup>
            </Fieldset>
            <Fieldset legend="Address">
              <FieldsGroup>
                <FieldInput type="text" id="address-id-1" label="City" />
                <FieldInput type="text" id="address-id-2" label="State" />
              </FieldsGroup>
            </Fieldset>
            <FieldSubmit>
              <input value="submit" type="submit" />
            </FieldSubmit>
          </form>
        </Container>
      </Spacing>
    </>
  );
}
